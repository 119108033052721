@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
.navbar {
	display: flex;
	align-items: center;
	/* width: 100vw; */
	height: 5rem;
	font-family: "Rubik", sans-serif;
	justify-content: space-between;
}
.link {
	text-decoration: none;
}
.left,
.right {
	display: flex;
	align-items: center;
}
.right {
	margin-right: 2rem;
}
.right > div {
	margin-left: 10px;
}
.left > div {
	margin-left: 1.3rem;
}
.nav-logo {
	font-size: 2rem;
	color: #ff9f1c;
}
.nav-text {
	font-weight: 600;
	font-size: 2rem;
	color: #003566;
	font-family: "Raleway", sans-serif;
}
.btn {
	padding: 15px 10px 15px 10px;
	border-radius: 10px;
	font-size: 1.3rem;
}
.btn:hover {
	cursor: pointer;
}
.login {
	color: #000;
}
.signup {
	background-color: rgb(49, 80, 175);
	color: #fff;
	font-family: "Raleway", sans-serif;
}
.login:hover {
	color: #444444;
	user-select: none;
	cursor: pointer;
}
.signup:hover {
	background-color: rgb(35, 77, 204);
	user-select: none;
	cursor: pointer;
}
.hidden {
	display: none;
}
.link.nav {
	margin-left: 1rem;
}
.all-banner-container {
	scroll-snap-type: y mandatory;
}
