@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.left-section {
	display: flex;
	flex-direction: column;
	width: 45vw;
	justify-content: center;
	min-height: calc(100vh - 5rem);
}
.right-section {
	display: flex;
	width: 40vw;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 5rem);
}
.text {
	width: 30vw;
}
.big {
	font-size: 4rem;
	font-family: "Raleway", sans-serif;
}
.small {
	font-size: 2rem;
}
.img-slider {
	width: 30vw;
	height: 80vh;
}
.img-front-banner {
	width: 40vw;
}
.slide {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mid-banner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.overlay-shadow {
	width: 80vw;
	height: 80vh;
	position: absolute;
	background: rgb(79, 79, 79);
	background: linear-gradient(
		0deg,
		rgba(79, 79, 79, 0.6783088235294117) 0%,
		rgba(255, 255, 255, 0.04525560224089631) 100%
	);
	z-index: 1;
}
.mid-banner-img {
	width: 80vw;
	height: 80vh;
}
.mid-banner-text {
	position: absolute;
	z-index: 2;
	color: #fff;
	font-size: 5rem;
}
@media screen and (max-width: 768px) {
	.left-section,
	.right-section {
		width: 90vw;
		min-height: 100vh;
		height: fit-content;
	}
	.text {
		width: 90vw;
	}
	.img-slider {
		width: 90vw;
	}
	.img-front-banner {
		width: 88vw;
	}
	.mid-banner-img {
		height: fit-content;
		width: 90vw;
	}
	.mid-banner-container {
		height: 60vh;
	}
	.overlay-shadow {
		width: 90vw;
		height: fit-content;
	}
	.mid-banner-text {
		font-size: 3rem;
	}
}
