.second-banner {
	height: 100vh;
	background-image: url("../../data/bannerPic.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
@media screen and (max-width: 760px) {
	.typical-writting {
		width: 90vw;
		text-align: center;
	}
	.sec-ban-text {
		font-size: 3rem;
	}
}
