.banner-text {
	width: 37vw;
	text-align: justify;
	margin-bottom: 1rem;
}
.date-text {
	font-size: 1rem;
}
.banner-text {
	font-size: 1.4rem;
	white-space: pre-wrap;
	font-weight: 400;
}
.img-banner {
	width: 35vw;
	max-height: 90vh;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.img-banner:hover {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.banner:nth-child(even) {
	flex-direction: row-reverse;
}
.banner:nth-child(even) > .left-section {
	margin-left: 2rem;
}
.banner {
	height: 100vh;
}
@media screen and (max-width: 768px) {
	.banner {
		height: 200vh;
		flex-direction: row-reverse;
	}
	.banner-text {
		width: 90vw;
		font-size: 1.1rem;
	}
	.img-banner {
		width: 90vw;
	}
	.banner:nth-child(even) > .left-section {
		margin-left: 0rem;
	}
}
