@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
::-webkit-scrollbar-thumb {
	background: #ffab3d;
	border-radius: 4px;
}
::-webkit-scrollbar-track {
	background: #fff;
}
::-webkit-scrollbar {
	width: 1rem;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.banner-text {
	width: 37vw;
	text-align: justify;
	margin-bottom: 1rem;
}
.date-text {
	font-size: 1rem;
}
.banner-text {
	font-size: 1.4rem;
	white-space: pre-wrap;
	font-weight: 400;
}
.img-banner {
	width: 35vw;
	max-height: 90vh;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.img-banner:hover {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.banner:nth-child(even) {
	flex-direction: row-reverse;
}
.banner:nth-child(even) > .left-section {
	margin-left: 2rem;
}
.banner {
	height: 100vh;
}
@media screen and (max-width: 768px) {
	.banner {
		height: 200vh;
		flex-direction: row-reverse;
	}
	.banner-text {
		width: 90vw;
		font-size: 1.1rem;
	}
	.img-banner {
		width: 90vw;
	}
	.banner:nth-child(even) > .left-section {
		margin-left: 0rem;
	}
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.left-section {
	display: flex;
	flex-direction: column;
	width: 45vw;
	justify-content: center;
	min-height: calc(100vh - 5rem);
}
.right-section {
	display: flex;
	width: 40vw;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 5rem);
}
.text {
	width: 30vw;
}
.big {
	font-size: 4rem;
	font-family: "Raleway", sans-serif;
}
.small {
	font-size: 2rem;
}
.img-slider {
	width: 30vw;
	height: 80vh;
}
.img-front-banner {
	width: 40vw;
}
.slide {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mid-banner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.overlay-shadow {
	width: 80vw;
	height: 80vh;
	position: absolute;
	background: rgb(79, 79, 79);
	background: linear-gradient(
		0deg,
		rgba(79, 79, 79, 0.6783088235294117) 0%,
		rgba(255, 255, 255, 0.04525560224089631) 100%
	);
	z-index: 1;
}
.mid-banner-img {
	width: 80vw;
	height: 80vh;
}
.mid-banner-text {
	position: absolute;
	z-index: 2;
	color: #fff;
	font-size: 5rem;
}
@media screen and (max-width: 768px) {
	.left-section,
	.right-section {
		width: 90vw;
		min-height: 100vh;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.text {
		width: 90vw;
	}
	.img-slider {
		width: 90vw;
	}
	.img-front-banner {
		width: 88vw;
	}
	.mid-banner-img {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		width: 90vw;
	}
	.mid-banner-container {
		height: 60vh;
	}
	.overlay-shadow {
		width: 90vw;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.mid-banner-text {
		font-size: 3rem;
	}
}

.footer-container {
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.copyright {
	font-size: 1.5rem;
	font-family: "Raleway", sans-serif;
	font-weight: 400;
}
.socials {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 15vw;
	font-size: 1.5rem;
}
.social:hover {
	cursor: pointer;
	color: #ff9f1c;
}
.social {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 10vw;
}
@media screen and (max-width: 760px) {
	.socials {
		width: 30vw;
	}
	.social {
		width: 15vw;
	}
}

.second-banner {
	height: 100vh;
	background-image: url(/static/media/bannerPic.b608e916.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
@media screen and (max-width: 760px) {
	.typical-writting {
		width: 90vw;
		text-align: center;
	}
	.sec-ban-text {
		font-size: 3rem;
	}
}

.navbar {
	display: flex;
	align-items: center;
	/* width: 100vw; */
	height: 5rem;
	font-family: "Rubik", sans-serif;
	justify-content: space-between;
}
.link {
	text-decoration: none;
}
.left,
.right {
	display: flex;
	align-items: center;
}
.right {
	margin-right: 2rem;
}
.right > div {
	margin-left: 10px;
}
.left > div {
	margin-left: 1.3rem;
}
.nav-logo {
	font-size: 2rem;
	color: #ff9f1c;
}
.nav-text {
	font-weight: 600;
	font-size: 2rem;
	color: #003566;
	font-family: "Raleway", sans-serif;
}
.btn {
	padding: 15px 10px 15px 10px;
	border-radius: 10px;
	font-size: 1.3rem;
}
.btn:hover {
	cursor: pointer;
}
.login {
	color: #000;
}
.signup {
	background-color: rgb(49, 80, 175);
	color: #fff;
	font-family: "Raleway", sans-serif;
}
.login:hover {
	color: #444444;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
}
.signup:hover {
	background-color: rgb(35, 77, 204);
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
}
.hidden {
	display: none;
}
.link.nav {
	margin-left: 1rem;
}
.all-banner-container {
	-ms-scroll-snap-type: y mandatory;
	    scroll-snap-type: y mandatory;
}

