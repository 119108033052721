@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
.footer-container {
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.copyright {
	font-size: 1.5rem;
	font-family: "Raleway", sans-serif;
	font-weight: 400;
}
.socials {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 15vw;
	font-size: 1.5rem;
}
.social:hover {
	cursor: pointer;
	color: #ff9f1c;
}
.social {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 10vw;
}
@media screen and (max-width: 760px) {
	.socials {
		width: 30vw;
	}
	.social {
		width: 15vw;
	}
}
